<template>
  <div class="line-list container">
    <div class="title-box">
      <a href="/storage">
        <font-awesome-icon icon="fa-solid fa-chevron-left" />내 저장소
      </a>
      <h2>간편인증</h2>
    </div>

    <!-- 진태님 만들 간편인증 선택 box -->
    <div class="input-select"></div>
    <!-- 세은님 만들 인풋 받는 box -->

    <b-container fluid>
      <b-input-group class="mt-3">
        <b-form-input
          id="name"
          v-model="name"
          :state="nameState"
          pattern="[ㄱ-ㅎ가-힣a-zA-z]{20}"
          placeholder="이름"
          maxlength="20"
          trim
          required
        ></b-form-input>
      </b-input-group>

      <b-input-group class="mt-3" v-if="isNeedBirth($route.params.category)">
        <b-form-input
          id="birth"
          v-model="birth"
          pattern="[0-9]{8}"
          placeholder="생년월일 8자리"
          maxlength="8"
          trim
          required
        ></b-form-input>
      </b-input-group>
      <!-- ----------- -->
      <b-input-group class="mt-3">
        <b-input-group-append
          ><b-form-select
            v-model="selected"
            :options="options"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-select> </b-input-group-append
        ><b-form-input
          id="phoneNumber"
          v-model="phoneNumber"
          maxlength="8"
          pattern="[0-9]{8}"
          required
          placeholder="휴대폰 번호"
        ></b-form-input>
      </b-input-group>

      <!-- ----- -->

      <!-- -------- -->
      <b-input-group class="mt-3" v-if="isNeedSSN($route.params.category)">
        <b-form-input
          id="ssnFront"
          placeholder="900000"
          pattern="[0-9]{6}"
          maxlength="6"
          trim
          required
          v-model="ssnFront"
        ></b-form-input>
        <p style="display: inline-block;width: 30px;padding-top: 5px;">-</p>
        <b-form-input
          id="ssnBack"
          placeholder="*******"
          pattern="[0-9]{7}"
          maxlength="7"
          trim
          required
          v-model="ssnBack"
        ></b-form-input>
      </b-input-group>
      <!-- ---------- -->
    </b-container>
    <!-- <b-button
      @click="submitForm"
      variant="primary"
      class="btn-md"
      style="width: ;"
      >다음</b-button
    > -->
    <div>
      <b-button
        id="show-btn"
        variant="primary"
        @click="$bvModal.show('bv-modal-example')"
        >다음</b-button
      >

      <b-modal id="bv-modal-example" hide-footer>
        <template #modal-title>
          <h3>
            건강검진 내역 조회 약관에 동의합니다.
          </h3>
        </template>
        <div class="d-block text-center">
          <p>[건강검진] 개인정보(민감정보) 수집 및 이용 동의 (필수)</p>
          <p>[건강보험공단] 개인정보 이용 동의 (필수)</p>
          <p>[건강보험공단] 서비스 이용 약관 동의 (필수)</p>
          <p>[건강보험공단] 제 3자 정보 제공 동의 (필수)</p>
          <p>[건강보험공단] 고유식별번호 처리 동의 (필수)</p>
        </div>
        <b-button
          class="mt-3"
          block
          @click="
            myClickEvent($bvModal);
            goToNextStep();
          "
          >동의하고 확인</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxOne: "",
      name: "",
      birth: "",
      selected: "010",
      options: [
        { item: "010", name: "010" },
        { item: "011", name: "011" },
        { item: "016", name: "016" },
        { item: "018", name: "018" }
      ],
      phoneNumber: "",
      ssnFront: "",
      ssnBack: ""
    };
  },
  methods: {
    goToNextStep() {
      this.$router.push("3");
    },
    isNeedBirth(category) {
      if (category === `medicalcheckup`) {
        return true;
      }
    },
    isNeedSSN(category) {
      if (category === `medicalrecords`) {
        return true;
      }
    },
    myClickEvent(bvModal) {
      bvModal.hide("bv-modal-example");
      this.submitForm();
    },
    submitForm() {
      // 여기에서 폼 데이터를 처리하거나 서버에 전송하는 로직을 추가하세요.

      console.log("이름:", this.name);
      console.log("생년월일:", this.birth);

      console.log("휴대폰 번호:", this.selected, "-", this.phoneNumber);

      console.log("주민등록번호:", this.ssnFront, "-", this.ssnBack);
    }
  }
};
</script>

<style>
.line-list.container {
  padding: 0;
  width: 95%;
}

.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 93%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 6.9444vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
.form-control {
  margin: 0px auto;
  display: block;
  width: 90%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group {
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 90%;
}

.btn-primary {
  margin: 5% 0px;
  color: #fff;
  background-color: #f4433661;
  border-color: #fa1100;
  /* width: 90%; */
}
</style>
